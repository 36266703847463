<template>
  <div class="pay-order-wrapper">
    <my-nav-bar :isClinet="true" @goback="goback" isManual></my-nav-bar>
    <div class="pay-order">
      <div class="pay-total-wrapper">
        <van-form>
          <van-field
            v-model="state.totalPrice"
            label="费用合计"
            label-width="80"
            input-align="right"
            readonly
          />
          <van-field
            label="支付方式"
            label-width="80"
            :border="false"
            readonly
            required
          />
          <div class="pay-type">
            <my-status-list
              :statusList="payLists3"
              :checkedData="payType"
              :isDisabled="dataList.isDisabled"
              @getStatusList="getStatusList"
            ></my-status-list>
          </div>
          <div class="pay-link" v-if="payType == 1">
            <p class="tips">
              请将链接或链接生成的二维码发送给学生，并提醒学生使用系统浏览器打开支付。
            </p>
            <p class="link-address">{{ linkUrl }}</p>
          </div>
          <div class="pay-underLine" v-if="payType == 3 && state.orderDiscount != 1">
            <van-field
              v-model="payUnderLine.currentPayMoney"
              label="本次支付金额"
              label-width="90"
              @input="changeInput(payUnderLine.currentPayMoney)"
              type="number"
              placeholder="请输入本次支付金额"
              required
              input-align="right"
            />
            <van-field
              v-model="arrearage"
              input-align="right"
              label="欠费金额"
              label-width="80"
              readonly
            />
            <van-field
              v-model="payUnderLine.message"
              rows="1"
              autosize
              label="备注"
              input-align="right"
              type="textarea"
              placeholder="请输入备注"
            />
          </div>
        </van-form>
      </div>
      <!-- currentType 1是整专业 2课程 -->
      <!-- <div v-if="state.currentType === 1" class="pay-total-message">{{state.title}}</div> -->
      <div class="pay-list-wrapper" v-if="payType !== 1">
        <p
          class="title"
          :class="[
            payType !== 3 && state.checkSuccessList && state.checkSuccessList.length
              ? 'active'
              : '',
          ]"
        >
          {{ currentTitle }}
        </p>
        <div
          v-if="
            state.checkSuccessList &&
            state.checkSuccessList.length &&
            state.currentType === 2
          "
          class="pay-item-content"
        >
          <div
            class="project-item"
            v-for="item in state.checkSuccessList"
            :key="item.goodsNo"
          >
            <p class="porject-title">
              {{ `${item.goodsName || item.name} - ${item.goodsNo}` }}
            </p>
            <div class="other-message">
              <span>{{ item.applyType === 0 ? '应用型' : '社会型'  }}</span>
              <span class="type"
                >{{
                  item.consumeType == 1
                    ? "购买"
                    : item.consumeType == 2
                    ? "升级"
                    : item.consumeType == 3
                    ? "续费"
                    : ""
                }}·{{ item.signLevel === 1 ? "智慧学" : "精品学" }}</span
              >
              <!-- <span>¥{{ item.money }}</span> -->
              <span>¥{{ state.totalPrice }}</span>
            </div>
          </div>
        </div>
        <div class="pay-pic" v-if="payType == 2">
          <p class="pay-total-money">费用合计: {{ state.totalPrice }}</p>
          <div class="pay-pic-content">
            <div class="pay-pic-wrapper">
              <img :src="picture" alt />
            </div>
          </div>
        </div>
      </div>
      <div class="pay-list-wrapper" v-else>
        <p class="title">{{ currentTitle }}</p>
      </div>
      <div class="footer-wrapper" v-if="payType == 3">
        <my-footer
          :successSubmitRight="false"
          :isShowLeft="false"
          :loadingRight="loading"
          :rightText="payType === 2 ? '保存为图片' : '提交审核'"
          @handleButtonRight="submitRight"
        ></my-footer>
      </div>
      <div class="footer-wrapper" v-if="payType == 1">
        <my-footer
          leftText="生成二维码"
          rightText="复制链接"
          @handleButtonRight="copyLink"
          @handleButtonLeft="openQrModal"
        ></my-footer>
      </div>
    </div>
    <van-popup
      v-model:show="showQrModal"
      :close-on-click-overlay="false"
      :style="{ borderRadius: '20px', overflowY: 'inherit' }"
    >
      <div class="qr-wrapper">
        <div class="qr-img">
          <img :src="withOutLogoQr + `?content=${linkUrl}&height=400&width=400`" alt="" />
        </div>
        <p class="tips">请将二维码保存到相册 建议用系统浏览器扫码支付</p>
        <span class="close" @click="closeQrModal">
          <i class="iconfont hxb-icon_tc_guanbi"></i>
        </span>
      </div>
    </van-popup>
  </div>
</template>
<script>
import myNavBar from "./navBar";
import { Form, Field, Toast, Popup } from "vant";
import myStatusList from "@/components/statusList";
import { payLists2, goodsBigTypeList } from "@/utils/const";
import { computed, onUnmounted, reactive, ref } from "vue";
import { withOutLogoQr } from "@/utils/urls";
import { checkSnPays } from "@/utils/check";
import { getPayCode, offLinePay, checkPaySuccess } from "@/api/common";
import myFooter from "@/components/buttonGroup";
import useClipboard from "vue-clipboard3";
import html2canvas from "html2canvas";
import { useRouter } from "vue-router";
import { payForLinkUrl } from "@/utils/urls";
export default {
  props: {
    dataList: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    myFooter,
    myStatusList,
    myNavBar,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
  },
  setup(props, context) {
    const { toClipboard } = useClipboard();
    const router = useRouter();
    const state = reactive(props.dataList);
    const payLists3 = ref(payLists2);
    const showQrModal = ref(false);
    const linkUrl = `${payForLinkUrl}?sn=${state.sn}`;
    // const state = reactive({
    //   sn: `569720210529170591`,
    // });
    const payUnderLine = reactive({
      currentPayMoney: "", // 本次支付
      message: "",
    });
    // 当前信息
    const loading = ref(false);
    const picture = ref("");
    let checkPay = null;
    const payType = ref(1); // 1链接支付 2微信 3 线下
    const getOrderPicture = () => {
      getPayCode("WXPAY", state.sn).then((res) => {
        if (res && res.body) {
          picture.value = `${withOutLogoQr}?content=${encodeURIComponent(
            res.body
          )}&width=320&height=320`;
        }
      });
    };
    // 当前商品title信息
    const titleMessage = () => {
      let str = "";
      if (state.checkSuccessList && state.checkSuccessList.length) {
        let typeMessage;
        if (state.checkSuccessList[0].signType !== 1) {
          typeMessage = goodsBigTypeList.filter((item) => {
            return item.type == state.checkSuccessList[0].signType;
          })[0].name;
        } else {
          typeMessage = "单门课程";
        }
        if (state.currentType === 1) {
          //单门课程
          str = `${typeMessage}·${state.checkSuccessList[0].name}.${state.checkSuccessList.length}门课程`;
        } else {
          str = `${typeMessage}·${state.checkSuccessList[0].name}·整专业·${
            state.checkSuccessList[0].signLevel === 1 ? "智慧学" : "精品学"
          }`;
        }
        return str;
      } else {
        if(state.currentType === 2) {
          
        }
      }
    };
    const currentTitle = titleMessage();
    // 改变支付方式
    const getStatusList = (item) => {
      payType.value = item.type;
      if (payType.value == 2) {
        getOrderPicture();
      }
    };
    // 检测是否支付成功
    if (state.sn) {
      checkPay = setInterval(() => {
        getCheckPaySuccess();
      }, 10000);
    }
    const getCheckPaySuccess = () => {
      checkPaySuccess({
        sn: state.sn,
      }).then((res) => {
        if (res && res.body) {
          router.push({
            path: "/paySuccess",
            query: {
              type: 2,
              title: state.reportMessage,
            },
          });
        }
      });
    };

    onUnmounted(() => {
      clearInterval(checkPay);
    });
    const goback = () => {
      context.emit("closePay");
    };
    const arrearage = computed(() => {
      return state.totalPrice - payUnderLine.currentPayMoney;
    });
    const submitRight = () => {
      if (payType.value == 2) {
        html2canvas(document.body, {
          useCORS: true,
        }).then(function (canvas) {
          let baseUrl = canvas.toDataURL("image/png");
          let aNode = document.createElement("a");
          aNode.setAttribute("href", baseUrl);
          aNode.setAttribute("download", "支付订单截图");
          document.body.appendChild(aNode);
          aNode.click();
        });
      } else {
        if(state.orderDiscount != 1) {
          if (!payUnderLine.currentPayMoney) {
            return Toast({
              message: "支付金额不能为空!",
            });
          }
        } else {
          // 优惠订单 不能填写支付金额直接赋值全部金额
          payUnderLine.currentPayMoney = state.totalPrice
        }
        checkSnPays(
          () => {
            const params = {
              payMoney: payUnderLine.currentPayMoney,
              remark: payUnderLine.message,
              sn: state.sn,
            };
            offLinePay(params).then((res) => {
              if (res && res.body) {
                router.push({
                  path: "/paySuccess",
                  query: {
                    type: 3,
                    title: state.reportMessage,
                  },
                });
              }
            });
          },
          state.sn,
          () => {
            router.go(-1);
          }
        );
      }
    };
    const changeInput = (val) => {
      if (Number(val) >= state.totalPrice) {
        payUnderLine.currentPayMoney = state.totalPrice;
      } else if (Number(val) < 0) {
        payUnderLine.currentPayMoney = 0;
      } else if (val === "-") {
        payUnderLine.currentPayMoney = 0;
      }
    };
    // 复制链接
    const copyLink = async () => {
      if (!linkUrl) return;
      await toClipboard(linkUrl);
      Toast({
        message: "复制成功",
      });
    };
    // 打开二维码弹窗
    const openQrModal = () => {
      showQrModal.value = true;
    };
    // 关闭弹窗
    const closeQrModal = () => {
      showQrModal.value = false;
    };
    // getOrderPicture();
    return {
      payLists3,
      picture,
      payType,
      goback,
      state,
      arrearage,
      currentTitle,
      linkUrl,
      showQrModal,
      copyLink,
      changeInput,
      payUnderLine,
      withOutLogoQr,
      openQrModal,
      submitRight,
      closeQrModal,
      loading,
      getStatusList,
    };
  },
};
</script>
<style lang="less" scoped>
.pay-order-wrapper {
  height: calc(100% - 88px);
  .qr-wrapper {
    width: 600px;
    padding: 98px 40px 60px 40px;
    box-sizing: border-box;
    border-radius: 30px;
    position: relative;
    .qr-img {
      margin: 0 auto;
      width: 404px;
      height: 434px;
      padding: 4px;
      box-sizing: border-box;
      background: url("/imgs/pay_bg@2x.png") no-repeat center;
      background-size: contain;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tips {
      padding: 0px 60px;
      box-sizing: border-box;
      margin-top: 40px;
      text-align: center;
    }
    .close {
      position: absolute;
      bottom: -120px;
      left: 50%;
      margin-right: 30px;
      i {
        font-size: 60px;
        color: #fff;
      }
    }
  }
}
.pay-order {
  margin-top: 88px;
  min-height: 100%;
  background: #f5f5f5;
  padding: 24px 24px 160px 24px;
  box-sizing: border-box;
  .pay-total-wrapper {
    padding: 32px;
    border-radius: 24px;
    box-sizing: border-box;
    background: #fff;
    .pay-link {
      margin-top: 40px;
      padding: 16px 24px;
      background: #fafafa;
      border-radius: 12px;
      box-sizing: border-box;
      .tips {
        font-size: 26px;
        color: #666;
      }
      .link-address {
        font-size: 26px;
        color: #1678ff;
      }
    }
  }
  .pay-list-wrapper {
    margin-top: 20px;
    padding: 32px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 24px;
    .title {
      font-size: 30px;
      font-weight: bold;
      color: #333;
      text-align: center;
    }
    .active {
      padding-bottom: 32px;
      border-bottom: 1px solid #eee;
    }
    .pay-item-content {
      margin-top: 32px;
      .project-item {
        background: #fafafa;
        border-radius: 12px;
        padding: 24px;
        box-sizing: border-box;
        margin-bottom: 16px;
        .porject-title {
          font-size: 28px;
          font-weight: bold;
          color: #333;
          margin-bottom: 12px;
        }
        .other-message {
          font-size: 26px;
          color: #999;
          & > span:first-child {
            padding-right: 20px;
          }
          & > span:last-child {
            padding-left: 20px;
          }
          .type {
            padding: 0px 20px;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
          }
        }
      }
    }
    .pay-pic {
      margin-top: 16px;
      .pay-total-money {
        font-size: 30px;
        color: #333;
        font-weight: bold;
        text-align: center;
        margin-bottom: 32px;
      }
      .pay-pic-content {
        width: 400px;
        height: 430px;
        background: url("/imgs/pay_bg@2x.png") no-repeat center;
        margin: 0 auto;
        padding: 32px 40px 28px 40px;
        background-size: contain;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .pay-total-message {
    margin-top: 20px;
    padding: 32px;
    box-sizing: border-box;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #333;
    background: #fff;
    border-radius: 24px;
  }
  .footer-wrapper {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    background: #fff;
  }
}
</style>

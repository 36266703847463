import { checkSnPay } from '@/api/common';
import { Toast } from 'vant';
export const reg_phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
export const reg_idCard = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
// 验证当前订单是否可以支付

export const checkSnPays = (cb, sn, err) => {
  const params = {
    sn: sn,
  };
  checkSnPay(params).then((res) => {
    if (res && res.body.payStatus) {
      cb();
    } else {
      const tipMessage = [
        '学生不能购买',
        '订单已关闭',
        '订单已被支付',
        '订单不存在',
      ];
      const message = tipMessage[res.reason - 1];
      Toast({
        message: message,
        onClose: () => {
          err();
        },
      });
    }
  });
};
